import * as _getIntrinsic2 from "get-intrinsic";
var _getIntrinsic = _getIntrinsic2;
try {
  if ("default" in _getIntrinsic2) _getIntrinsic = _getIntrinsic2.default;
} catch (e) {}
import * as _callBind2 from "call-bind";
var _callBind = _callBind2;
try {
  if ("default" in _callBind2) _callBind = _callBind2.default;
} catch (e) {}
var exports = {};
var GetIntrinsic = _getIntrinsic;
var callBind = _callBind;

// eslint-disable-next-line no-extra-parens
var $indexOf = callBind( /** @type {typeof String.prototype.indexOf} */GetIntrinsic("String.prototype.indexOf"));

/** @type {import('.')} */
exports = function callBoundIntrinsic(name, allowMissing) {
  // eslint-disable-next-line no-extra-parens
  var intrinsic = /** @type {Parameters<typeof callBind>[0]} */GetIntrinsic(name, !!allowMissing);
  if (typeof intrinsic === "function" && $indexOf(name, ".prototype.") > -1) {
    return callBind(intrinsic);
  }
  return intrinsic;
};
export default exports;